import { Grid, Breadcrumbs, Typography } from "@mui/material";
import React, { useEffect, useState, useRef, useCallback } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useNavigate, useParams } from "react-router-dom";
import MuiAccordion from "@mui/material/Accordion";
import MuiAccordionSummary from "@mui/material/AccordionSummary";
import MuiAccordionDetails from "@mui/material/AccordionDetails";
import { styled } from "@mui/material/styles";
import ArrowIconRight from "../../Assets/Icons/Arrow_Small_Right.svg";
import PDFIcon from "../../Assets/Icons/document_icon.svg";
import NaacExData from "../../Assets/JSON/NAAC/NAACExtended_Data.json";
import "../../Styles/Naac.css";

const NACCExtended = () => {
  const [expanded, setExpanded] = useState(0);
  const [selectedNaccData, setSelectedNaccData] = useState([]);
  const isMounted = useRef(true);
  const navigate = useNavigate();
  const parms = useParams();
  const NACCExtended_DATA = NaacExData?.NaacExtended_Headings;
  const NACCSubheadingData = NaacExData.NaacExtended_Data;

  const handleChange = (panel, index) => (event, newExpanded) => {
    localStorage.setItem("expandedIndex", newExpanded ? panel : 1);
    setExpanded(newExpanded ? panel : false);
  };
  const Accordion = styled((props) => (
    <MuiAccordion disableGutters elevation={0} square {...props} />
  ))(({ theme }) => ({
    border: `1px solid ${theme.palette.divider}`,
    "&:not(:last-child)": {
      borderBottom: 0,
    },
    "&:before": {
      display: "none",
    },
  }));

  const AccordionDetails = styled(MuiAccordionDetails)(({ theme }) => ({
    padding: theme.spacing(2),
    borderTop: "1px solid rgba(0, 0, 0, .125)",
    color: theme.palette.mode === "dark" ? "#fff" : "black",
  }));

  const AccordionSummary = styled((props) => (
    <MuiAccordionSummary
      expandIcon={<img src={ArrowIconRight} alt="Arrow Icon" />}
      {...props}
    />
  ))(({ theme }) => ({
    "& .MuiAccordionSummary-expandIconWrapper.Mui-expanded": {
      transform: "rotate(90deg)",
      backgroundColor: theme.palette.mode === "dark" ? "#ffff" : "#eeee",
      borderRadius: "50%",
    },
  }));

  const filterData = useCallback(
    (selected) => {
      console.log(selected);
      const filteredData = NACCSubheadingData.filter(
        (Naccdata) => Naccdata.ID === selected.ID
      );
      setSelectedNaccData(filteredData);
      navigate(
        `/NAACExtended/${
          selected.ID + "/" + selected?.Subheading.replace(/\s/g, "-")
        }`
      );
    },
    [NACCSubheadingData]
  );

  useEffect(() => {
    if (NACCSubheadingData && NACCSubheadingData.length > 0) {
      filterData(
        parms.id === isNaN || parms.name === undefined
          ? { ID: 11, Subheading: "Student" }
          : { ID: parseInt(parms.id), Subheading: parms?.name }
      );
    }

    return () => {
      isMounted.current = false;
    };
  }, [NACCSubheadingData, filterData]);

  const handlePdfClick = (urldata) => {
    if (!urldata.pathurl.trim()) {
      toast.error("There is no file", {
        position: toast.POSITION.TOP_RIGHT,
      });
    } else {
      navigate("/extendednaac/" + urldata.ID + "/" + urldata.pathname);
    }
  };

  return (
    <section className="container py-5">
      <Breadcrumbs
        separator="›"
        sx={{ width: "100%", display: "block", marginBottom: "10px" }}
        aria-label="breadcrumb"
      >
        <Link underline="hover" color="white" to="/">
          <b>Home</b>
        </Link>
        <Link underline="hover" to="/NAAC">
          <b>NAAC</b>
        </Link>
        <Typography color="text.primary">
          <b>Supporting Docs</b>
        </Typography>
      </Breadcrumbs>
      <div>
        <Grid container>
          <Grid items xs={12} md={3}>
            {NACCExtended_DATA &&
              NACCExtended_DATA.map((items, parentIndex) => {
                const isExpanded = expanded === parentIndex;
                return (
                  <Accordion
                    key={parentIndex}
                    expanded={isExpanded}
                    onChange={handleChange(parentIndex)}
                  >
                    <AccordionSummary
                      style={{
                        backgroundColor: isExpanded
                          ? "rgb(25, 47, 89)"
                          : "#D0D0D0",
                        color: isExpanded ? "white" : "inherit",
                      }}
                      onClick={() => {
                        if (!isExpanded) {
                          const firstSubheading = items.SubHeading[0];
                          filterData(firstSubheading);
                        }
                      }}
                    >
                      <Typography>{items.Heading}</Typography>
                    </AccordionSummary>
                    {items?.SubHeading &&
                      items.SubHeading.map((subdata, i) => {
                        return (
                          <AccordionDetails
                            key={i}
                            onClick={() => filterData(subdata)}
                            sx={{
                              "& .MuiTypography-root": {
                                fontSize: "0.9rem",
                                margin: "auto",
                                position: "relative",
                                left: "15px",
                              },
                            }}
                            className={
                              selectedNaccData.some(
                                (data) => data.ID === subdata.ID
                              )
                                ? "selected cursor-pointer"
                                : "cursor-pointer"
                            }
                          >
                            <Typography>{subdata.Subheading}</Typography>
                          </AccordionDetails>
                        );
                      })}
                  </Accordion>
                );
              })}
          </Grid>
          <Grid items xs={12} md={8} className="px-5">
            {selectedNaccData.length > 0 && selectedNaccData[0]?.URLs ? (
              <div>
                {selectedNaccData.map((HeadItem) =>
                  Object.entries(HeadItem.URLs).map(([key, value]) => (
                    <div key={key}>
                      <div>
                        <Typography
                          component="h4"
                          variant="h5"
                          className="py-4"
                        >
                          {value.map((items) => items.Title)}
                        </Typography>
                      </div>
                      <div className="d-flex flex-wrap">
                        {value.length > 0 ? (
                          value.map((urldata, index) => (
                            <div key={index} className="col-md-4 mb-4">
                              <div>
                                <img
                                  src={PDFIcon}
                                  onClick={() => handlePdfClick(urldata)}
                                  alt="PDF Files"
                                  className="cursor-pointer"
                                />
                              </div>
                              <div>
                                <Typography component="p" variant="subtitle1">
                                  {urldata.filename}
                                </Typography>
                              </div>
                            </div>
                          ))
                        ) : (
                          <div>No data found</div>
                        )}
                      </div>
                    </div>
                  ))
                )}
              </div>
            ) : (
              <div>
                <h4>No Data Selected</h4>
              </div>
            )}
          </Grid>
        </Grid>
      </div>
      <ToastContainer />
    </section>
  );
};

export default NACCExtended;
