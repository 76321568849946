import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import _set from "lodash/set";
import _get from "lodash/get";
import _isEmpty from "lodash/isEmpty";
import _keys from "lodash/keys";
// import _findIndex from 'lodash/findIndex';
import feedback_data from "../../Assets/JSON/Feedbackjson/Feedback.json";
import Feedback_DropDown_Data from "../../Assets/JSON/Feedbackjson/Feedback_dropdown.json";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import Radio from "@mui/material/Radio";
import FormControlLabel from "@mui/material/FormControlLabel";
import SubmittedImage from "../../Assets/Images/FeedbackSubmitted.png";
import Axios from "axios";
// import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
import "../../Styles/Feedback.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Tooltip from "@mui/material/Tooltip";

const Feedback = () => {
  const [selectedTab, setSelectedTab] = useState("");
  const [feedBackData, setFeedBackData] = useState({
    ...feedback_data.Feedback_data,
  });
  const [activeButton, setActiveButton] = useState(0);
  const [Submitted, setSubmitted] = useState(false);
  const [errorMessage, setErrorMessage] = useState("");

  const activeFormName = _get(
    feedback_data,
    `Feedback_forms[${activeButton}].match_name`,
    ""
  );
  const activeForm = _get(feedback_data, `Feedback_data.${activeFormName}`, {});
  const formTabs = _keys(_get(activeForm, "tabs", {}));
  const activeTab = formTabs.indexOf(selectedTab);

  useEffect(() => {
    setActiveButton(0);
    setFeedBackData(formTabs);
  }, []);

  const onChangeEvent = (path, event) => {
    _set(feedBackData, path, event.target.value);
    setFeedBackData({ ...feedBackData });
  };

  const handleButtonClick = (buttonId) => {
    setActiveButton(buttonId);
    setSelectedTab("");
  };

  const onSubmit = async () => {
    try {
      const apiUrl = "https://devapi.dbraei.edu.in/api/v1/feedbacks";

      // Structure the selected questions and answers based on the active tab
      const selectedTabData = _get(
        feedBackData,
        `${activeFormName}.tabs.${selectedTab}`,
        []
      );

      // Collect all personal details and tab question responses
      const payload = {
        type: activeForm.title, // selected tab type
        personalDetails: {
          user_id: 1,
          student_id: 1,
          teacher_id: 0,
          department_name: "", // Add relevant details
          department_code: "", // Add relevant details
          college_id: 1,
          college_name: "abcd",
          created_by: 1,
          updated_by: 1,
        },
        questions: selectedTabData.map((question) => ({
          question: question.question, // Capture question text
          answer: _get(
            feedBackData,
            `${activeFormName}.tabs.${selectedTab}[${question.id}].options`
          ), // Capture the selected answer for this question
        })),
      };

      const response = await Axios.post(apiUrl, payload, {
        timeout: 5000,
        headers: {
          "Content-Type": "application/json",
        },
      });

      console.log("Submitted Data:", payload);
      console.log("API Response: ", response.data);

      // Reset the feedback data to its initial state
      setFeedBackData({ ...feedback_data.Feedback_data });
      setSelectedTab(""); // Reset the selected tab
      setSubmitted(true); // Indicate that submission was successful
    } catch (error) {
      console.error("Error submitting data:", error);
      toast.error("Error: Something Went Wrong..!", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  const onNext = () => {
    setSelectedTab(formTabs[activeTab + 1]);
  };

  const text_input = (text, path) => {
    return (
      <div>
        <TextField
          id="standard-basic"
          onChange={(e) => onChangeEvent(path, e)}
          value={_get(feedBackData, path, "")}
          label={text.Name}
          variant="standard"
          className="w-100"
          type="text"
        />
      </div>
    );
  };

  const textarea_input = (text, path) => {
    return (
      <div>
        <TextField
          multiline
          rows={4}
          id="standard-basic"
          onChange={(e) => onChangeEvent(path, e)}
          value={_get(feedBackData, path, "")}
          label={text.Name}
          variant="standard"
          className="w-100"
        />
      </div>
    );
  };

  const dropdown_input = (dropdown, path) => {
    return (
      <>
        <label htmlFor="dropdown">{dropdown.Name}</label>
        <select
          id="dropdown"
          onChange={(e) => onChangeEvent(path, e)}
          value={_get(feedBackData, path, "")}
          className="custom-dropdown w-100"
        >
          <option value="">Please select any Option</option>
          {Feedback_DropDown_Data[dropdown.match_name]?.map((option) => (
            <option key={option.Name} value={option.Name}>
              {option.Name}
            </option>
          ))}
        </select>
      </>
    );
  };

  const yesNoRadioButton = (e, key, path) => {
    const value = e.target.checked;
    const options = {
      Yes: false,
      No: false,
    };
    options[key] = value;
    _set(feedBackData, path, options);
    setFeedBackData({ ...feedBackData });
  };

  const ratingRadioButton = (e, key, path) => {
    const value = e.target.checked;
    const options = {
      rating5: false,
      rating4: false,
      rating3: false,
      rating2: false,
      rating1: false,
    };
    options[key] = value;
    _set(feedBackData, path, options);
    setFeedBackData({ ...feedBackData });
  };

  const YesNo_Question = (YesNo_Data, path) => {
    console.log("Data:", YesNo_Data.Yes_No_Question);
    return (
      <div>
        {YesNo_Data?.Yes_No_Question?.map((YesNoQuestion, index) => (
          <div key={index} className="mb-3 color-blue">
            <p>{YesNoQuestion.questions}</p>
            <div>
              {Object.keys(YesNoQuestion?.options)?.map((key) => (
                <FormControlLabel
                  key={`${index}_${key}`}
                  control={<Radio />}
                  label={key.toString()}
                  onChange={(e) =>
                    yesNoRadioButton(
                      e,
                      key,
                      `${path}.Yes_No_Question[${index}].options`
                    )
                  }
                  checked={_get(
                    feedBackData,
                    `${path}.Yes_No_Question[${index}].options.${key}`,
                    false
                  )}
                />
              ))}
            </div>
          </div>
        ))}
      </div>
    );
  };

  const rating_data = (feedback_Data, path) => {
    console.log("Checking:", feedback_Data);
    return (
      <TableContainer component={Paper} className="feedback-table-container">
        <Table aria-label="feedback table">
          <TableHead>
            <TableRow className="table-header" style={{ color: "white" }}>
              {feedback_Data?.header?.map((items, index) => (
                <TableCell
                  key={index}
                  className="vertical-text"
                  style={
                    index === 0
                      ? { minWidth: "250px", width: "50%" }
                      : { width: "10%" }
                  }
                >
                  {items.title} {items.name}
                </TableCell>
              ))}
            </TableRow>
          </TableHead>
          <TableBody>
            {feedback_Data?.Questions?.map((row, index) => (
              <TableRow key={index} className="table-row">
                <TableCell
                  component="th"
                  scope="row"
                  className="question-cell"
                  style={{ width: "50%" }}
                >
                  {row.question}
                </TableCell>
                {Object.keys(row?.options)?.map((key) => (
                  <TableCell
                    align="center"
                    key={key}
                    className="radio-cell"
                    style={{ width: "10%" }}
                  >
                    <Radio
                      checked={_get(
                        feedBackData,
                        `${path}.Questions[${index}].options.${key}`,
                        false
                      )}
                      onChange={(e) =>
                        ratingRadioButton(
                          e,
                          key,
                          `${path}.Questions[${index}].options`
                        )
                      }
                      value={key.toString()}
                      name={`radio-buttons-${row.id}`}
                      inputProps={{ "aria-label": key.toString() }}
                    />
                  </TableCell>
                ))}
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  };

  const validateText = (text, path) => {
    if (!_isEmpty(text.trim())) {
      _set(feedBackData, path, text.trim());
      setFeedBackData({ ...feedBackData });
      return true;
    } else {
      console.error("Text is required.");
      toast.error("Text is required.", {
        position: "top-right",
        autoClose: 5000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  };

  const validateDropdown = (value, path) => {
    if (!_isEmpty(value)) {
      _set(feedBackData, path, value);
      setFeedBackData({ ...feedBackData });
      return true;
    } else {
      toast.error("Please select an option.", {
        position: "top-right",
        autoClose: 5000, // milliseconds
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
      return false;
    }
  };

  const onSub = (dta) => {
    setSelectedTab(dta);
    console.log("Result: ", dta);
  };

  const handleSubmit = (event) => {
    event.preventDefault();
    setErrorMessage("");

    const isTextValid = validateText(
      _get(feedBackData, `${activeFormName}.headers.exampleText.value`),
      `${activeFormName}.headers.exampleText.value`
    );
    const isDropdownValid = validateDropdown(
      _get(feedBackData, `${activeFormName}.headers.exampleDropdown.value`),
      `${activeFormName}.headers.exampleDropdown.value`
    );

    if (isTextValid && isDropdownValid) {
      console.log("Form submitted successfully!");
      // Continue with form submission logic
    } else {
      console.error("Form submission failed due to validation errors.");
      // Display error message to the user
      toast.error(errorMessage || "Form validation failed.", {
        position: "top-right",
        autoClose: 5000,
        hideProgressBar: false,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
      });
    }
  };

  return (
    <section className="container py-5">
      <ToastContainer />
      <div className="feedBack_container row">
        {Submitted !== true ? (
          <div className="col-sm-4 mx-3 mb-3">
            <div
              style={{ backgroundColor: "#999BE5", textAlign: "center" }}
              className="text-white p-3 rounded"
            >
              <h4 className="m-0">Feedback Forms</h4>
            </div>
            {feedback_data.Feedback_forms &&
              feedback_data.Feedback_forms.map((items, index) => {
                const isActive = activeButton === index;
                return (
                  <div
                    className={`border d-flex align-items-center my-2 rounded ${
                      isActive ? "bg-gray text-white" : "bg-white"
                    }`}
                    key={index}
                  >
                    <Button
                      variant="text"
                      style={{
                        width: "100%",
                        backgroundColor: isActive ? "#6c757d" : "#f8f9fa",
                        color: isActive ? "white" : "#007bff",
                        borderRadius: "0px",
                      }}
                      onClick={() => {
                        handleButtonClick(index);
                      }}
                      className="p-3"
                    >
                      <b>{items.title}</b>
                    </Button>
                  </div>
                );
              })}
          </div>
        ) : null}

        {Submitted !== true ? (
          !_isEmpty(activeForm) && (
            <div className="col-sm-8">
              <h4>{activeForm.title}</h4>
              <form onSubmit={handleSubmit}>
                <div className="row mb-4">
                  {Object.keys(activeForm.headers).map((items, index) => (
                    <div key={index} className="col-sm-6 mb-3">
                      {activeForm.headers[items].type === "dropdown" && (
                        <div>
                          {dropdown_input(
                            activeForm.headers[items],
                            `${activeFormName}.headers.${items}.value`
                          )}
                        </div>
                      )}

                      {activeForm.headers[items].type === "text" && (
                        <>
                          {text_input(
                            activeForm.headers[items],
                            `${activeFormName}.headers.${items}.value`
                          )}
                        </>
                      )}
                      {activeForm.headers[items].type === "textarea" && (
                        <>
                          {textarea_input(
                            activeForm.headers[items],
                            `${activeFormName}.headers.${items}.value`
                          )}
                        </>
                      )}
                    </div>
                  ))}
                </div>

                <div className="d-flex my-4">
                  {Object.keys(activeForm.tabs).map((items, index) => (
                    <div className="mx-2" key={index}>
                      <Button
                        variant="contained"
                        sx={{
                          width: "100%",
                          maxWidth: "200px",
                          height: "40px",
                          fontSize: "0.765rem",
                          backgroundColor:
                            items === selectedTab ? "#6c757d" : "#999BE5",
                          color: "white",
                          "&:hover": {
                            backgroundColor:
                              items === selectedTab ? "#6c757d" : "#0056b3",
                          },
                          "@media (max-width: 600px)": {
                            maxWidth: "150px",
                            height: "40px",
                            fontSize: "0.775rem",
                          },
                          "@media (max-width: 400px)": {
                            fontSize: "0.75rem",
                          },
                        }}
                        onClick={() => onSub(items)}
                      >
                        <Tooltip title={items.length > 10 ? items : ""} arrow>
                          <span>
                            {items.length > 11
                              ? `${items.slice(0, 11)}...`
                              : items}
                          </span>
                        </Tooltip>
                      </Button>
                    </div>
                  ))}
                </div>

                <div className="row mb-4">
                  {Array.isArray(activeForm.tabs[selectedTab]) ? (
                    activeForm.tabs[selectedTab].map((items, index) => (
                      <div className="col-sm-6 mb-3" key={index}>
                        {items.type === "dropdown" && (
                          <>
                            {dropdown_input(
                              items,
                              `${activeFormName}.tabs.${selectedTab}[${index}].value`
                            )}
                          </>
                        )}
                        {items.type === "text" && (
                          <>
                            {text_input(
                              items,
                              `${activeFormName}.tabs.${selectedTab}[${index}].value`
                            )}
                          </>
                        )}
                        {items.type === "textarea" && (
                          <>
                            {textarea_input(
                              items,
                              `${activeFormName}.tabs.${selectedTab}[${index}].value`
                            )}
                          </>
                        )}
                      </div>
                    ))
                  ) : (
                    <div className="col-sm-12">
                      {activeForm.tabs[selectedTab]?.type === "rating" && (
                        <>
                          {YesNo_Question(
                            activeForm.tabs[selectedTab],
                            `${activeFormName}.tabs.${selectedTab}`
                          )}
                          {rating_data(
                            activeForm?.tabs[selectedTab],
                            `${activeFormName}.tabs.${selectedTab}`
                          )}
                        </>
                      )}
                    </div>
                  )}
                </div>

                <div className="mt-3 text-end">
                  <Button
                    className="px-5"
                    variant="contained"
                    sx={{
                      backgroundColor:
                        formTabs.length - 1 === activeTab
                          ? "#28a745"
                          : "#0000F9",
                      color: "white",
                      "&:hover": {
                        backgroundColor:
                          formTabs.length - 1 === activeTab
                            ? "#218838"
                            : "#0056b3",
                      },
                    }}
                    onClick={
                      formTabs.length - 1 === activeTab ? onSubmit : onNext
                    }
                  >
                    {formTabs.length - 1 === activeTab ? "Submit" : "Next"}
                  </Button>
                </div>
              </form>
            </div>
          )
        ) : (
          <div className="col-sm-12 text-center">
            <img
              src={SubmittedImage}
              alt="Feedback Submitted"
              className="img-fluid mb-4"
            />
            <Button
              variant="contained"
              component={Link}
              to="/"
              sx={{
                backgroundColor: "#007bff",
                color: "white",
                "&:hover": {
                  backgroundColor: "#0056b3",
                },
              }}
            >
              Go to Main
            </Button>
          </div>
        )}
      </div>
    </section>
  );
};

export default Feedback;
